<template>
	<div class="global-container">
		<div class="login-container">
			<img id="logo" alt="Structure logo" src="../assets/structure-logo-wht.png" />
			<span class="beta">Beta</span>
			<h1>Cloud Platform</h1>
			<login-button v-if="!isAccessTokenSet" />
		</div>
	</div>
  </template>

  <script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import LoginButton from './LoginButton.vue';
  import { useRouter } from 'vue-router';

  export default {
	name: 'OuterLoginContainer',
	components: {
		LoginButton,
	},
	setup() {
		const store = useStore();
		const router = useRouter();

		const isAccessTokenSet = computed(() => {
			return store.getters.isAccessTokenSet;
		});

		// Redirect if the user is logged in
		if (isAccessTokenSet.value) {
			router.push('/dashboard/account');
		}

		return {
		isAccessTokenSet,
		router
		};
	},
  };
  </script>

  <style scoped lang="scss">
  @import './OuterLoginContainer.scss'
  </style>
