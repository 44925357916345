import { createRouter, createWebHistory } from 'vue-router';
import { authGuard } from "@auth0/auth0-vue";


import OuterLoginContainer from '@/components/OuterLoginContainer.vue';
import DashboardContainer from '@/components/DashboardContainer.vue';
import DashboardApp from '@/components/DashboardApp.vue';
import Auth0Callback from '@/components/Auth0Callback.vue';
import EulaPage from '@/components/EulaPage.vue';


const routes = [
    {
        path: '',
        redirect: '/login',
    },
    {
        path: '/login',
        component: OuterLoginContainer
    },
    {
        path: '/auth0callback',
        name: 'auth0callback',
        component: Auth0Callback,
    },
    {
        name: 'Dashboard',
        path: '/dashboard/:activeView',
        component: DashboardContainer,
        beforeEnter: authGuard,
        children: [
            {
                name: 'DashboardView',
                path: '',
                components: {
                    default: DashboardApp,
                },
            },
        ],
    },
    {
        name: 'Eula',
        path: '/eula',
        component: EulaPage
    }
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});


export { router, routes };
