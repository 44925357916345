<template>
    <div class="dashboard-container">
        <DashboardTopNav />
        <div class="app-wrapper">
            <DashboardAppNav
                :active-view="activeView"
                @item-clicked="handleNavItemClicked"
            />
            <router-view :active-view="activeView" />
            <SessionSignOutModal
                v-if="showSignOutModal"
                :showModal="openSignOutModal"
                @close="closeSignOutModal"
            />
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useAuth0 } from '@auth0/auth0-vue';
import { jwtDecode } from "jwt-decode";

import DashboardTopNav from './DashboardTopNav.vue';
import DashboardAppNav from './DashboardAppNav.vue';
import SessionSignOutModal from './modals/SessionSignOutModal.vue';

export default {
    components: {
        DashboardTopNav,
        DashboardAppNav,
        SessionSignOutModal
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const { getAccessTokenSilently, user } = useAuth0();

        const routePath = computed(() => route.path);
        const segments = routePath.value.split('/');
        const activeView = segments[segments.length - 1];
        const showSignOutModal = computed(() => store.state.showSignOutModal);

        const handleNavItemClicked = (clickedItemLabel, responseData) => {
            store.commit('SET_ACTIVE_VIEW', clickedItemLabel);
            store.commit('SET_API_RESPONSE_OBJECT', responseData);
        };

        const openSignOutModal = () => {
            store.dispatch('showSignOutModal');
        };

        const closeSignOutModal = () => {
            store.dispatch('closeSignOutModal');
        };

        const fetchData = async () => {
            store.commit('SET_SHOW_LOADING_MODAL', true);
            try {
                const token = await getAccessTokenSilently();
                const decoded = jwtDecode(token);
                const permissions = decoded.permissions;

                const roles = user.value['https://structure.io/roles'];
                store.commit('SET_AUTHENTICATION', { userIsAuthorized: true, roles: roles, permissions: permissions });
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                store.commit('SET_SHOW_LOADING_MODAL', false);
            }
        };

        // Fetch data when component is mounted
        fetchData();

        return {
            activeView,
            showSignOutModal,
            handleNavItemClicked,
            openSignOutModal,
            closeSignOutModal
        };
    },
};
</script>

<style scoped lang="scss">
@import './DashboardContainer.scss'
</style>
