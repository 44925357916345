import { createAuth0 } from '@auth0/auth0-vue';
import { createApp } from 'vue';
import store from './store';
import App from './App.vue';
import { router } from './router/index';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {    faKey,
            faUser,
            faXmark,
            faChevronRight,
            faCode,
            faBan,
            faPenToSquare,
            faCircleCheck,
            faCopy,
            faCheck,
            faEye,
            faSpinner,
            faCloud,
            faPaperPlane,
            faArrowUpRightFromSquare,
            faFlagCheckered,
            faSortUp,
            faSortDown,
            faSort,
            faMagnifyingGlass
        } from '@fortawesome/free-solid-svg-icons';

library.add(faKey);
library.add(faUser);
library.add(faXmark);
library.add(faChevronRight);
library.add(faCode);
library.add(faBan);
library.add(faPenToSquare);
library.add(faCircleCheck);
library.add(faCopy);
library.add(faCheck);
library.add(faEye);
library.add(faSpinner);
library.add(faCloud);
library.add(faPaperPlane);
library.add(faArrowUpRightFromSquare);
library.add(faFlagCheckered);
library.add(faSortUp);
library.add(faSortDown);
library.add(faSort);
library.add(faMagnifyingGlass);

const app = createApp(App);

app.use(store)
.use(router)
.use(
    createAuth0({
        domain: process.env.VUE_APP_AUTH0_DOMAIN,
        clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
        authorizationParams: {
            redirect_uri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
            audience: process.env.VUE_APP_AUTH0_API_AUDIENCE
        },
    })
);

app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
